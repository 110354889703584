export const ipConfig = {
  yaman: {
    id: 0,
    name: "yaman",
    address: "c83e25be912dfa8970e07034ddaee6d05ef3d9ee",
    percent: 10,
  },
  louis: {
    id: 1,
    name: "louis",
    address: "36150eb1DEf833f5253E88e808fEBdf1db1Cb812",
    percent: 15,
  },
  match: {
    id: 2,
    name: "match",
    address: "c2686f48aD2c31EA6C14778CC33e4e61747076D6",
    percent: 15,
  },
  aaron: {
    id: 3,
    name: "aaron",
    address: "1D6be0ECe6163ee1079f62931e9125E70Bef26f9",
    percent: 15,
  },
  mazen: {
    id: 4,
    name: "mazen",
    address: "a80ddcacb9ddd5e26f2752fdd29d40df7b63a291",
    percent: 15,
  },
  saed: {
    id: 5,
    name: "saed",
    address: "0e5fa3aA9cAe4d709765fBC0eC239882d812F00c",
    percent: 15,
  },
  gaurav: {
    id: 6,
    name: "gaurav",
    address: "414e73B00D1876c00723BA53279632D1Aa479e24",
    percent: 15,
  },
  rashid: {
    id: 7,
    name: "rashid",
    address: "812d2e275eec352fd78a31d851c28a92470fd4f1",
    percent: 20,
  },
  christos: {
    id: 8,
    name: "christos",
    address: "20429a9D75b1A933D5d2a0e91aBd86f58572e1B2",
    percent: 15,
  },
  carlton: {
    id: 9,
    name: "carlton",
    address: "f945679fCC1Dca75807874318b20A40Bc91E691D",
    percent: 5,
  },
  habtoor: {
    id: 10,
    name: "habtoor",
    address: "2018ba070EA103cEDC8202b7539DEA001AF137D1",
    percent: 3.5,
  },
  vinay: {
    id: 11,
    name: "vinay",
    address: "1dCFF45707C26C408A9f266aFC8a67DCD77BBB32",
    percent: 50,
  },
  dan: {
    id: 12,
    name: "dan",
    address: "2F90bEc7D17A4865c9746D8875793f2Ef8771b12",
    percent: 15,
  },
  salman: {
    id: 12,
    name: "salman",
    address: "C046Bcc3d864d9e65d1a2588f82A89EB2b50120f",
    percent: 15,
  },
  firdaus: {
    id: 13,
    name: "firdaus",
    address: "1b69Be7d5F5C62335e0998fc2C3b0d62a1B2A0E0",
    percent: 15,
  },
  firdaus2: {
    id: 14,
    name: "firdaus2",
    address: "2989D20BcbDB9BdC4E09d7bC24577d30E24DD602",
    percent: 15,
  }
};
