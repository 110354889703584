import { Component, OnInit } from "@angular/core";
import { HomeService } from "src/app/_services/home.service";
import { ipConfig } from "../../_config/data";

interface CustomerEntity {
  id: number;
  name: string;
  address: string;
  percent: number;
}

export interface Customer {
  [key: string]: CustomerEntity;
}

interface BGExpected {
  expectedReward24HTotal: number;
  expectedRewardWeekTotal: number;
  expectedReward24USDHTotal: number;
  expectedRewardWeekUSDTotal: number;
}

interface Rewards {
  [key: string]: {
    percent: number;
    expectedReward24H: number;
    expectedRewardWeek: number;
    expectedReward24HUSD: number;
    expectedRewardWeekUSD: number;
    bgExpected: {
      expectedReward24H: number;
      expectedRewardWeek: number;
      expectedReward24HUSDTotal: number;
      expectedRewardWeekUSDTotal: number;
    };
  };
}
@Component({
  selector: "app-admin-page",
  templateUrl: "./admin-page.component.html",
  styleUrls: ["./admin-page.component.scss"],
})
export class AdminPageComponment implements OnInit {
  customers: Customer = ipConfig;
  customersData = Object.values(this.customers);
  usersData: Rewards;
  expectedReward24HTotal: number = 0;
  expectedRewardWeekTotal: number = 0;
  expectedReward24USDHTotal: number = 0;
  expectedRewardWeekUSDTotal: number = 0;
  expectedReward24HUSDTotalSubject: number = 0;
  expectedRewardWeekUSDTotalSubject: number = 0;
  bgExpectedTotal: BGExpected = {
    expectedReward24HTotal: 0,
    expectedRewardWeekTotal: 0,
    expectedReward24USDHTotal: 0,
    expectedRewardWeekUSDTotal: 0,
  };
  monthlyEarningsETH: number = 0;
  yearlEarningsETH: number = 0;
  monthlyEarningsUSD: number = 0;
  yearlyEarningsUSD: number = 0;
  constructor(private _mainService: HomeService) {}
  ngOnInit() {
    this._mainService.getUsersRewards(this.customers);
    this._mainService.getCombinedData().subscribe(
      (res) => {
        this.usersData = res;
      },
      (err) => {
        console.log(err);
      }
    );
    this._mainService.getTotalExpectedReward24HTotal().subscribe((res) => {
      this.expectedReward24HTotal = res;
    });
    this._mainService.getTotalExpectedRewardWeek().subscribe((res) => {
      this.expectedRewardWeekTotal = res;
    });
    this._mainService.getExpectedReward24HUSDTotalSubject().subscribe((res) => {
      this.expectedReward24HUSDTotalSubject = res;
    });
    this._mainService
      .getExpectedRewardWeekUSDTotalSubject()
      .subscribe((res) => {
        this.expectedRewardWeekUSDTotalSubject = res;
      });
    this._mainService.getBgExpectedTotal().subscribe((res) => {
      this.bgExpectedTotal = res;
    });
    this._mainService.getmonthlyEarningsETHSubject().subscribe((res) => {
      this.monthlyEarningsETH = res;
    });
    this._mainService.getyearlEarningsETHSubject().subscribe((res) => {
      this.yearlEarningsETH = res;
    });
    this._mainService.getmonthlyEarningsUSDSubject().subscribe((res) => {
      this.monthlyEarningsUSD = res;
    });
    this._mainService.getyearlyEarningsUSDSubject().subscribe((res) => {
      this.yearlyEarningsUSD = res;
    });
  }
}
