import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Customer } from "../components/admin-page/admin-page.component";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import BigNumber from "bignumber.js";

export interface RewardsResponse {
  earningStats: Object[];
  expectedReward24H: number;
  expectedRewardWeek: number;
  pendingPayouts: [];
  succeedPayouts: Object[];
  totalPaid: number;
  totalUnpaid: number;
}

interface UserData {
  expectedReward24H: number;
  expectedRewardWeek: number;
  expectedReward24HUSD: number;
  expectedRewardWeekUSD: number;
  bgExpected: {
    expectedReward24H: number;
    expectedRewardWeek: number;
    expectedReward24HUSD: number;
    expectedRewardWeekUSD: number;
  };
}

@Injectable({
  providedIn: "root",
})
export class HomeService {
  public ethUrl = environment.ethURL.toLowerCase();
  private poolURL = environment.baseUrl + "/stats/pool";
  private ethVale =
    "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD";
  private usersDataSubject: BehaviorSubject<UserData> =
    new BehaviorSubject<UserData>({
      expectedReward24H: 0,
      expectedRewardWeek: 0,
      expectedReward24HUSD: 0,
      expectedRewardWeekUSD: 0,
      bgExpected: {
        expectedReward24H: 0,
        expectedRewardWeek: 0,
        expectedReward24HUSD: 0,
        expectedRewardWeekUSD: 0,
      },
    });
  private usersData: UserData = {
    expectedReward24H: 0,
    expectedRewardWeek: 0,
    expectedReward24HUSD: 0,
    expectedRewardWeekUSD: 0,
    bgExpected: {
      expectedReward24H: 0,
      expectedRewardWeek: 0,
      expectedReward24HUSD: 0,
      expectedRewardWeekUSD: 0,
    },
  };
  private expectedReward24HTotalSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private expectedRewardWeekTotalSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private expectedRewardWeekTotal: number = 0;
  private expectedReward24HTotal: number = 0;
  private bgExpectedTotalSubject: BehaviorSubject<{
    expectedReward24HTotal: number;
    expectedRewardWeekTotal: number;
    expectedReward24USDHTotal: number;
    expectedRewardWeekUSDTotal: number;
  }> = new BehaviorSubject<{
    expectedReward24HTotal: number;
    expectedRewardWeekTotal: number;
    expectedReward24USDHTotal: number;
    expectedRewardWeekUSDTotal: number;
  }>({
    expectedReward24HTotal: 0,
    expectedRewardWeekTotal: 0,
    expectedReward24USDHTotal: 0,
    expectedRewardWeekUSDTotal: 0,
  });
  private bgExpectedTotal: {
    expectedReward24HTotal: number;
    expectedRewardWeekTotal: number;
    expectedReward24USDHTotal: number;
    expectedRewardWeekUSDTotal: number;
  } = {
    expectedReward24HTotal: 0,
    expectedRewardWeekTotal: 0,
    expectedReward24USDHTotal: 0,
    expectedRewardWeekUSDTotal: 0,
  };
  private expectedReward24HUSDTotalSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private expectedRewardWeekUSDTotalSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private expectedRewardWeekUSDTotal: number = 0;
  private expectedReward24HUSDTotal: number = 0;
  private monthlyEarningsETH: number = 0;
  private yearlEarningsETH: number = 0;
  private monthlyEarningsUSD: number = 0;
  private yearlyEarningsUSD: number = 0;
  private monthlyEarningsETHSubject: BehaviorSubject<number> =
    new BehaviorSubject(0);
  private yearlEarningsETHSubject: BehaviorSubject<number> =
    new BehaviorSubject(0);
  private monthlyEarningsUSDSubject: BehaviorSubject<number> =
    new BehaviorSubject(0);
  private yearlyEarningsUSDSubject: BehaviorSubject<number> =
    new BehaviorSubject(0);

  constructor(private http: HttpClient) {}

  public expectedEarning(address: string) {
    const earningsURL =
      environment.baseUrl +
      "/stats/miner/" +
      address.toLowerCase() +
      "/ETH/billing-acc";
    return this.http.get(earningsURL);
  }

  public getpool() {
    return this.http.get(this.poolURL);
  }

  public getoverview(address: string) {
    const overviewURL =
      environment.baseUrl + "/stats/miner/" + address.toLowerCase() + "/ETH";
    return this.http.get(overviewURL);
  }

  public getEthValue() {
    return this.http.get(this.ethVale);
  }

  public getHashrates(address: string) {
    const hashRates =
      environment.baseUrl +
      "/stats/hashrates?minerAddress=" +
      address.toLowerCase() +
      "&coin=ETH&window=10m&limit=144&offset=0&worker=";
    return this.http.get(hashRates);
  }

  private getUserRewardsByAddress(address: string) {
    const rootURL = "https://hiveon.net/api/v1/stats/miner/";
    const curreny = "/ETH/billing-acc";
    return this.http.get<RewardsResponse>(`${rootURL}${address}${curreny}`);
  }

  public getUsersRewards(customers: Customer) {
    const ratioURL =
      "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD";
    this.http.get<{ USD: number }>(ratioURL).subscribe(({ USD: ratio }) => {
      for (let [_, { address, name, percent }] of Object.values(
        customers
      ).entries()) {
        this.getUserRewardsByAddress(address.toLowerCase()).subscribe(
          (res) => {
            const copiedState = { ...this.usersData };
            copiedState[name] = {
              percent,
              expectedReward24H: new BigNumber(res.expectedReward24H).toFixed(
                4
              ),
              expectedRewardWeek: new BigNumber(res.expectedRewardWeek).toFixed(
                4
              ),
              expectedReward24HUSD: new BigNumber(res.expectedReward24H)
                .multipliedBy(ratio)
                .toFixed(2),
              expectedRewardWeekUSD: new BigNumber(res.expectedRewardWeek)
                .multipliedBy(ratio)
                .toFixed(2),
              bgExpected: {
                expectedReward24H: new BigNumber(res.expectedReward24H)
                  .multipliedBy(percent)
                  .dividedBy(100)
                  .toFixed(4),
                expectedRewardWeek: new BigNumber(res.expectedRewardWeek)
                  .multipliedBy(percent)
                  .dividedBy(100)
                  .toFixed(4),
                expectedReward24HUSDTotal: new BigNumber(res.expectedReward24H)
                  .multipliedBy(percent)
                  .dividedBy(100)
                  .multipliedBy(ratio)
                  .toFixed(2),
                expectedRewardWeekUSDTotal: new BigNumber(
                  res.expectedRewardWeek
                )
                  .multipliedBy(percent)
                  .dividedBy(100)
                  .multipliedBy(ratio)
                  .toFixed(2),
              },
            };
            this.expectedReward24HTotal = new BigNumber(
              this.expectedReward24HTotal
            )
              .plus(res.expectedReward24H)
              .toNumber();
            this.expectedRewardWeekTotal = new BigNumber(
              this.expectedRewardWeekTotal
            )
              .multipliedBy(res.expectedRewardWeek)
              .toNumber();
            this.expectedReward24HUSDTotal = new BigNumber(
              this.expectedReward24HUSDTotal
            )
              .plus(res.expectedReward24H)
              .multipliedBy(ratio)
              .toNumber();
            this.expectedRewardWeekUSDTotal = new BigNumber(
              this.expectedRewardWeekUSDTotal
            )
              .plus(res.expectedRewardWeek)
              .multipliedBy(ratio)
              .toNumber();
            this.expectedReward24HUSDTotalSubject.next(
              this.expectedReward24HUSDTotal
            );
            this.expectedRewardWeekUSDTotalSubject.next(
              this.expectedRewardWeekUSDTotal
            );
            this.bgExpectedTotal = {
              expectedReward24HTotal: +new BigNumber(
                this.bgExpectedTotal.expectedReward24HTotal
              )
                .plus(
                  new BigNumber(res.expectedReward24H)
                    .multipliedBy(percent)
                    .dividedBy(100)
                    .toNumber()
                )
                .toFixed(4),
              expectedRewardWeekTotal: +new BigNumber(
                this.bgExpectedTotal.expectedRewardWeekTotal
              )
                .plus(
                  new BigNumber(res.expectedRewardWeek)
                    .multipliedBy(percent)
                    .dividedBy(100)
                    .toNumber()
                )
                .toFixed(4),
              expectedReward24USDHTotal: +new BigNumber(
                this.bgExpectedTotal.expectedReward24HTotal
              )
                .plus(
                  new BigNumber(res.expectedReward24H)
                    .multipliedBy(percent)
                    .dividedBy(100)
                    .toNumber()
                )
                .multipliedBy(ratio)
                .toFixed(2),
              expectedRewardWeekUSDTotal: +new BigNumber(
                this.bgExpectedTotal.expectedRewardWeekTotal
              )
                .plus(
                  new BigNumber(res.expectedRewardWeek)
                    .multipliedBy(percent)
                    .dividedBy(100)
                    .toNumber()
                )
                .multipliedBy(ratio)
                .toFixed(2),
            };
            this.bgExpectedTotalSubject.next(this.bgExpectedTotal);
            this.expectedReward24HTotalSubject.next(
              this.expectedReward24HTotal
            );
            this.expectedRewardWeekTotalSubject.next(
              this.expectedRewardWeekTotal
            );
            this.usersData = copiedState;
            this.monthlyEarningsETH = +new BigNumber(
              this.bgExpectedTotal.expectedRewardWeekTotal
            ).multipliedBy(4);
            this.yearlEarningsETH = +new BigNumber(
              this.bgExpectedTotal.expectedRewardWeekTotal
            ).multipliedBy(52);

            this.monthlyEarningsUSD = +new BigNumber(
              this.bgExpectedTotal.expectedRewardWeekUSDTotal
            ).multipliedBy(4);
            this.yearlyEarningsUSD = +new BigNumber(
              this.bgExpectedTotal.expectedRewardWeekUSDTotal
            ).multipliedBy(52);
            this.monthlyEarningsETHSubject.next(this.monthlyEarningsETH);
            this.yearlEarningsETHSubject.next(this.yearlEarningsETH);
            this.monthlyEarningsUSDSubject.next(this.monthlyEarningsUSD);
            this.yearlyEarningsUSDSubject.next(this.yearlyEarningsUSD);
            this.usersDataSubject.next(this.usersData);
          },
          (err) => {
            return throwError(err);
          }
        );
      }
    });
  }

  getTotalExpectedReward24HTotal(): Observable<number> {
    return this.expectedReward24HTotalSubject.asObservable();
  }

  getTotalExpectedRewardWeek(): Observable<number> {
    return this.expectedRewardWeekTotalSubject.asObservable();
  }

  getmonthlyEarningsETHSubject(): Observable<number> {
    return this.monthlyEarningsETHSubject.asObservable();
  }

  getyearlEarningsETHSubject(): Observable<number> {
    return this.yearlEarningsETHSubject.asObservable();
  }

  getmonthlyEarningsUSDSubject(): Observable<number> {
    return this.monthlyEarningsUSDSubject.asObservable();
  }

  getyearlyEarningsUSDSubject(): Observable<number> {
    return this.yearlyEarningsUSDSubject.asObservable();
  }

  getExpectedReward24HUSDTotalSubject(): Observable<number> {
    return this.expectedReward24HUSDTotalSubject.asObservable();
  }
  getExpectedRewardWeekUSDTotalSubject(): Observable<number> {
    return this.expectedRewardWeekUSDTotalSubject.asObservable();
  }

  getBgExpectedTotal() {
    return this.bgExpectedTotalSubject.asObservable();
  }

  getCombinedData(): Observable<any> {
    return this.usersDataSubject.asObservable();
  }
}
