import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { RouterModule, Routes } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { HttpClientModule } from "@angular/common/http";
import { HighchartsChartModule } from "highcharts-angular";
import { AdminPageComponment } from "./components/admin-page/admin-page.component";

const AppRoutes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "stats/c51c7e12c2c4faa367a5df361712288b5b903f3f8e00419d70325b9e2492fd9d", component: AdminPageComponment },
  { path: ":username", component: HomeComponent },
  { path: "**", redirectTo: "/home", pathMatch: "full" },
];

@NgModule({
  declarations: [AppComponent, HomeComponent, AdminPageComponment],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(AppRoutes),
    HttpClientModule,
    HighchartsChartModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
