import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/_services/home.service';
import { environment } from 'src/environments/environment';
import * as Highcharts from 'highcharts';
import { ipConfig } from '../_config/data'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public realTime: any;
  public setGlobal: any;
  public totalworkerCount: any;
  public addressSet: any;
  public setEarnings: any;
  public ethValue: any;
  public earingOne: any;
  public earingTwo: any;
  public earingThree: any;
  public earingFour: any;
  public earingSix: any;
  public chartDetails: any;
  public chartCallback;
  public updateFromInput = false;
  public chart;
  public account: any;
  public username: string;
  public isFound = false;

  constructor(
    private homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.username = this.route.snapshot.paramMap.get("username")
    if (ipConfig[this.username]) {
      this.isFound = true
      this.account = ipConfig[this.username]
    }
    this.chartCallback = chart => {
      this.chart = chart;
    };
  }

  // Highcharts: typeof Highcharts = Highcharts;
  chartData = [];
  //  chartOptions: Highcharts.Options = {
  //    series: this.chartData
  //  };
  public highcharts = Highcharts;
  public chartOptions = {
    chart: {
      type: 'line',
      backgroundColor: null,
      lineWidth: 0.5
    },
    series: [],
    title: {
      text: ''
    },
    xAxis: {
      title: {
        text: ''
      },
      categories: [],
      type: 'datetime',

      // Use the date format in the
      // labels property of the chart
      labels: {
        formatter: function () {
          return Highcharts.dateFormat('%b %d, %Y',
            this.value);
        }
      }
    },
    yAxis: {
      title: {
        text: ''
      }
    },
  }

  ngOnInit() {
    if (this.account && this.account.name == this.username) {
      this.addressSet = this.account.address;
      this.isFound = true
    } else {
      this.isFound = false
    }

    if (this.isFound) {
      this.homeService.getpool().subscribe(res => {
        this.realTime = res;
      });
      this.homeService.getoverview(this.addressSet).subscribe(res => {
        this.setGlobal = res;
        var offlineWork = this.setGlobal.offlineWorkerCount && this.setGlobal.offlineWorkerCount != null ? parseInt(this.setGlobal.offlineWorkerCount):0;
        var onlineWork = this.setGlobal.onlineWorkerCount && this.setGlobal.onlineWorkerCount != null ? parseInt(this.setGlobal.onlineWorkerCount):0;
        this.totalworkerCount = offlineWork + onlineWork;
      });
      this.homeService.expectedEarning(this.addressSet).subscribe(res => {
        this.setEarnings = res;
        this.homeService.getEthValue().subscribe(res => {
          this.ethValue = res;
          this.earingOne = this.ethValue.USD * this.setEarnings.expectedReward24H;
          this.earingTwo = this.ethValue.USD * this.setEarnings.expectedRewardWeek;
          this.earingThree = this.ethValue.USD * (this.setEarnings.totalUnpaid || 0);
          this.earingFour = this.ethValue.USD * (this.setEarnings.totalPaid || 0);
          this.earingSix = this.ethValue.USD * (this.setEarnings.succeedPayouts[0] && this.setEarnings.succeedPayouts[0].amount || 0);
        });
      });
      this.homeService.getHashrates(this.addressSet).subscribe(res => {
        this.chartDetails = res['items'].slice(0, 10);
        const hashrate = this.chartDetails.map(item => parseInt(item.hashrate));
        const meanHashrate = this.chartDetails.map(item => parseInt(item.meanHashrate));
        const reportedHashrate = this.chartDetails.map(item => parseInt(item.reportedHashrate));
        const timeData = this.chartDetails.map(item => new Date(item.timestamp));
        this.chartData = [
          {
            name: 'Hashrate Realtime',
            data: hashrate
          },
          {
            name: 'Hashrate Mean',
            data: meanHashrate
          },
          {
            name: 'Hashrate Reported',
            data: reportedHashrate
          }
        ];
        this.chartOptions.series = this.chartData;
        this.chartOptions.xAxis.categories = timeData;
        this.updateFromInput = true;
        if (this.chartData.length > 0) {
          setTimeout(() => {
            this.chart.reflow();
          }, 10);
        }
      });
    }
  }
}
